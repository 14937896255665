<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event ? event.decease_name : "MOURN WITH US"
              }}</a>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <section class="together_we_join minh-custom with-footer speaker-room-page">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <div>
              <div class="profile-pic" v-if="current_speaker != null && !isCloseInfo">
                <img
                  :src="current_speaker.profile_url"
                  class="mr-2 mb-1 live_profile_img"
                />
                {{ current_speaker.speaker_name }}
              </div>
              <h6
                style="max-height: calc(100vh - 59vh); overflow-y: auto"
                class="text-primary text-left mt-3"
                v-if="current_speaker != null && !isCloseInfo"
              >
                {{ current_speaker.bio }}
              </h6>
            </div>
          </div>
          <div class="col-md-9">
            <div class="live-video" style="padding: 0 0">
            <div
                class="frame-wrapper c-frame-wrapper"
                style="width: 100%; position: relative"
              >
            <div
                  class="frame-wrapper frame-outline"
                >
                  <img
                    :src="require('@/assets/img/frame.png')"
                    style="
                      position: absolute;
                      left: 0;
                      width: 100%;
                      height: 100%;
                    "
                  />
                <!-- <iframe
                  style="
                    height: 632px;
                    width: 100%;
                    padding: 25px 19px;
                    z-index: 9;
                    position: relative;
                  "
                  :src="roomUrl"
                  allow="camera; microphone; fullscreen;autoplay; encrypted-media"
                ></iframe> -->

                <!-- <iframe
                  style="
                    height: 632px;
                    width: 100%;
                    padding: 25px 19px;
                    z-index: 9;
                    position: relative;
                  "
                  src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e"
                  allow="autoplay; encrypted-media"
                  
                ></iframe> -->
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e&vwrs=1" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                <!-- style=" height: calc(100vh - 222px); width: 92%; padding: 0;
                position: relative; border: none; top: 51px; " -->
                <!-- style="
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: calc(100vh - 125px);
                  " -->
              </div>
            </div>
            </div>
          </div>
          <!-- <div class="col-md-1" style="color: white;">RoomID : {{ meetingId }} </div> -->
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="row align-items-center">
        <transition name="pop" appear>
          <div
            class="custom-modal note-modal"
            v-if="show_modal"
            id="EulogyNotes"
            tabindex="-1"
            role="dialog"
            aria-labelledby="Eulogy Notes"
            aria-hidden="true"
            v-draggable="draggableValue"
            style="z-index: 9"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-body" style="background-color: black">
                  <div class="row">
                    <div
                      class="col-lg-12"
                      style="justify-content: space-between; display: flex"
                    >
                      <h5 class="text-custom text-left">
                        <img
                          :src="require('@/assets/img/move_icon.png')"
                          :ref="handleId"
                        />Notes
                      </h5>
                      <b-button
                        type="button"
                        class="btn-primary mr-2"
                        @click="closemodel()"
                        >X</b-button
                      >
                    </div>
                    <div
                      class="col-lg-12 text-custom mt-4 scroll-notes"
                      style="max-height: 200px"
                    >
                      <p class="m-0">{{ speaker_notes }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div class="col-md-7">
          <div class="participate speaker_list">
            <ul class="list-group list-group-horizontal-md flex-wrap">
              <li
                v-for="speaker in speakers"
                :key="speaker.speaker_id"
                class="list-group-item py-1 px-0"
                style="background-color: black; margin-right: 0px"
              >
                <!-- <div class="videosession"> </div>-->

                <div style="margin-right: 15px">
                  <img :src="speaker.profile_url" class="img-fluid" />
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center" style="height: 130px">
            <div>
              <p class="text-primary text-left pr-2" style="font-size: 24px">
                LIVE MESSAGES:
              </p>
            </div>
            <div style="width: 325px" class="mx-2">
              <div
                class="my-auto d-flex align-items-center"
                v-if="latest_profile_url != '' && latest_profile_name != ''"
              >
                <div class="profile-pic border-0 mb-2">
                  <img
                    :src="latest_profile_url"
                    class="mr-3"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div
                    class="text-primary text-left mb-0"
                    style="font-size: 22px; font-weight: 600"
                  >
                    {{ latest_profile_name }}
                  </div>
                  <div
                    class="text-primary text-left mb-0"
                    style="overflow-y: auto; max-height: 100px"
                  >
                    {{ latest_message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 text-center text-lg-right" v-if="event">
          <div class="flower" style="margin-bottom: 0.5rem" v-for="(input,k) in imagesArray" :key="k">
                  <img
                    :src="input.program_url"
                    class="img-fluid"
                    alt="no img"
                    style="height: 100%"
                    @click="enlarge()"
                  />
                </div>
          </div>
      </div>
    </footer>

    <!-- Countdown -->
    <transition name="pop" appear>
      <div
        class="modal fade show custom-modal"
        v-if="show_count_modal"
        id="Countdown"
        tabindex="-1"
        role="dialog"
        aria-labelledby="Countdown"
        aria-hidden="true"
        style="z-index: 9000"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="width: 300px !important"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="text-custom my-4">You will be LIVE in</h5>
                  <div class="countdown">
                    {{ count_number }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="pop" appear>
      <div
        class="modal fade show program-modal"
        v-if="show_program_modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModal"
        aria-hidden="true"
        v-draggable="draggableValue"
        @click="closeModal"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeProgramModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="row" v-if="event">
                <div class="col-lg-12">
                  <img :src="event.program_url" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
import { mapActions } from "vuex";
import { Draggable } from "draggable-vue-directive";

import adapter from "webrtc-adapter";
window["adapter"] = adapter;

export default {
  directives: {
    Draggable,
  },
  data() {
    return {
      draggableValue: {
        handle: undefined,
      },
      handleId: "handle-id",
      speakers: [],
      visitors: [],
      checkTimer: null,
      event: null,
      event_id: null,
      content_type_id: null,
      show_modal: false,
      connection: null,
      devices: [],
      roomUrl: "",
      show_count_modal: false,
      count_number: 20,
      host_room_url:'',
      meetingId:'',
      current_speaker: null,
      latest_profile_url: "",
      latest_profile_name: "",
      latest_message: "",
      show_program_modal: false,
      imagesArray:[],
      isCloseInfo : false,
    };
  },
  mounted() {
    this.draggableValue.handle = this.$refs[this.handleId];
    console.log("88888888888888888", this.$route.fullPath);
    this.speaker_id = this.$route.fullPath.split("cid=")[1].split("&")[0];
    this.event_id = this.$route.fullPath.split("?")[1].split("&")[0];
    console.log("&&&&&&&&&&", this.event_id, this.speaker_id);
    this.getspeakerDetail();
    this.getDetail();
    this.connectSocket();
    this.getCondolences();
  },
  methods: {
    ...mapActions(["signOut"]),
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function(event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        console.log("&&&&&&&&&&&&&&&&&sss", data);
        if (data.type == "change_order") {
          console.log("44444444444444!!");
          ref.receiveMoveLive(data.order);
        } else if (data.type == "user_info") {
          console.log("!!!!!!!!!!!!!!!!!!!!");
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "end_live") {

          //route after finishes lives
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/parting/"+ref.event_id+"?x=" + randomnumber);
        } else if (data.type == "event_distroy") {
          // localStorage.setItem("Live_id", null);
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/profile?x=" + randomnumber);
          // window.location.reload();
        } else if (data.type == "start_count") {
          console.log("77777777777777");

          ref.countdownvalue();
        } else if (data.type == "change_speaker_bio") {
          ref.current_speaker = data.speaker;
          ref.isCloseInfo = data.isClose;
        }else if (data.type == "get_message") {
          ref.getCondolences();
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    getspeakerDetail() {},
    logout() {
      if (self.OTSession) {
        console.log("&&&&&&&&&&&&&", self.OTSession);
        self.OTSession.disconnect();
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
          live: 0,
          content_type_id: this.content_type_id,
        })
        .then((response) => {
          localStorage.setItem("Live_id", null);
          localStorage.setItem("deceased_name",'')
          this.signOut();
          this.$router.push("/login");
          window.location.reload();
        });
    },
    receiveMoveLive(order) {
      this.connection.close();
      if (order == 1) {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/start/"+this.event_id+"?x=" + randomnumber);
      } else if (order == 2) {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/live/"+this.event_id+"?x=" + randomnumber);
      }
    },
    closeModal() {
      this.show_modal = false;
    },
    countdownvalue() {
      this.show_count_modal = true;
      this.count_number = 20;
      this.countDownTimer();
    },
    countDownTimer() {
      if (this.count_number > 0) {
        setTimeout(() => {
          this.count_number -= 1;

          this.countDownTimer();
        }, 1000);
      } else {
        this.show_count_modal = false;
      }
    },

    onMessage(data) {
      console.log(data);
    },
    openpopup(speaker) {
      this.show_modal = false;
      this.show_modal = true;

      this.current_speaker = speaker;
      this.speaker_notes = speaker.notes;
      console.log("&&&&&&&&&&&&&&&&");
    },
    closemodel() {
      this.show_modal = false;
    },
    getDetail() {
      var ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/event/getEventDetail`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          this.event = res.data[0];
          this.host_room_url = response.data.data[0].hostRoomUrl;
          
          // this.meetingId=this.host_room_url.split('?')[0].split('/')[3]
          getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.event_id,
          })
          .then((response) => {
              this.imagesArray=response.data.detail;
              console.log("&&&&&&&&&&",this.imagesArray)
              if(this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            }
          })
          this.event.program_url = apiBaseUrl + "/" + res.data[0].program_url;

          getApiManager()
            .post(`${apiBaseUrl}/api/admin/speaker/get-info`, {
              content_type_id: this.speaker_id,
            })
            .then((response) => {
              let res1 = response.data;
              console.log("000000000", res1.detail);
              this.speaker_data = res1.detail;
              this.speaker_data.profile_url =
                apiBaseUrl + "/" + res1.detail.profile_url;
              console.log(
                "====================",
                this.speaker_data.profile_url
              );

              this.roomUrl =
                res.data[0].roomUrl +
                "?avatarUrl=" +
                this.speaker_data.profile_url;
              console.log(
                "ppppppppppppppppp",
                this.roomUrl,
                this.speaker_data.profile_url
              );
              if (res.status === true) {
                console.log("&&&&&&&&&&&&");
                getApiManager()
                  .post(`${apiBaseUrl}/api/speaker/event/getSpeakerByEventid`, {
                    event_id: this.event_id,
                  })
                  .then((response) => {
                    let res = response.data;
                    if (res.status === true) {
                      ref.speakers = res.detail.speakers;
                      var i;
                      for (i = 0; i < ref.speakers.length; i++) {
                        ref.speakers[i].profile_url =
                          apiBaseUrl + "/" + ref.speakers[i].profile_url;
                        ref.speakers[i].video_status = false;
                      }
                    }
                  })
                  .catch((error) => {});
              }
            });
        });
    },
    closeProgramModal() {
      this.show_program_modal = false;
    },
    enlarge() {
      this.show_program_modal = true;
    },
    getCondolences() {
      let ref = this;
      let i = 0;
      getApiManager()
        .post(`${apiBaseUrl}/api/admin/condolence/all`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let data = res.data;
            if (data.length > 0) {
              let condolence = {
                profile_url: data[0].content_type_id
                  ? apiBaseUrl + "/" + data[0].profile_url
                  : apiBaseUrl + "/" + data[0].visitor_profile_url,
                profile_name: data[0].content_type_id
                  ? data[0].speaker_name
                  : data[0].full_name,
                messages: [],
              };
              condolence.messages.push(data[0].message);
              this.latest_message = data[i].message;
              this.latest_visitor_id = data[i].content_type_id
                ? data[i].content_type_id
                : data[i].visitor_id;
              this.latest_profile_url = data[i].content_type_id
                ? apiBaseUrl + "/" + data[i].profile_url
                : apiBaseUrl + "/" + data[i].visitor_profile_url;
              this.latest_profile_name = data[i].content_type_id
                ? data[i].speaker_name
                : data[i].full_name;

              setInterval(() => {
                i++;
                if (i == data.length) {
                  i = 0;
                }
                this.latest_message = data[i].message;
                this.latest_visitor_id = data[i].content_type_id
                  ? data[i].content_type_id
                  : data[i].visitor_id;
                this.latest_profile_url = data[i].content_type_id
                  ? apiBaseUrl + "/" + data[i].profile_url
                  : apiBaseUrl + "/" + data[i].visitor_profile_url;
                this.latest_profile_name = data[i].content_type_id
                  ? data[i].speaker_name
                  : data[i].full_name;
              }, 30000);
            }
          }
        })
        .catch((error) => {});
    },
  },

  components: {},
  computed: {},
  watch: {},
};
</script>
<style scoped>
.participate .list-unstyled {
  display: flex !important;
}
#subscriber {
  display: flex !important;
}
.participate .text-label {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -8px !important;
  margin-right: 15px;
  top: 0 !important;
  background-color: #00000000 !important;
  position: absolute !important;
}
.participate .text-label span {
  background-color: #000 !important;
  left: unset !important;
  top: unset !important;
}
.navbar{
  padding: 0;
}
.frame-outline {
      width: 100%;
      padding: 47px;
    }
@media (min-width:350px) {
  .frame-outline {
    width: 100%;
    padding: 20px;
  }
    
}
@media (min-width:576px) {
  .frame-outline {
      width: 100%;
      padding: 20px;
    }
    
}

@media (min-width:768px) {
    .frame-outline {
      width: 100%;
      padding: 31px;
    }
}
</style>
